import { sanitizeHTML } from "@/lib/utils";

interface TextOnlySectionData {
  text: string;
}

interface TextOnlySectionProps {
  data?: TextOnlySectionData;
}

export const fields = ["text"];

export default function TextOnlySection({ data }: TextOnlySectionProps) {
  return (
    <section id="TextOnlySection" className="py-16 md:py-20 text-base">
      <div className="container">
        <div
          className="prose prose-h1:text-4xl prose-h1:font-display prose-h1:mb-8 prose-h2:text-3xl prose-h2:font-display prose-h2:mb-6 prose-h3:text-2xl prose-h3:font-display prose-h3:mb-4 prose-p:text-base prose-p:mb-4 prose-p:text-secondary-dark prose-ul:list-disc prose-ul:ml-4 prose-ul:mb-4 prose-li:mb-2 prose-a:text-primary hover:prose-a:text-primary-dark prose-a:no-underline max-w-none"
          dangerouslySetInnerHTML={sanitizeHTML(data?.text || "")}
        />
      </div>
    </section>
  );
}
