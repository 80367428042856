import Button from "@/components/ui/button";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  type CarouselApi,
} from "@/components/ui/carousel";
import Link from "@/components/ui/Link";
import { useState, useEffect } from "react";

interface TestimonialsTextCarouselProps {
  data?: any;
}

const testimonials = [
  {
    text: "Best of the Best! – I went to see Dr Julian De Silva for a deep plane facelift, upper and lower bleph and eyebrow lift for Ptosis. I had originally seen Dr Julian De Silva for a consultation back in 2017 and had followed his work for years, so I was familiar with his style of surgery and artistry. I had studied his work of the 'Golden Ratio' and even bought his book years ago.",
  },
  {
    text: "When I turned 40, I started noticing my jowls getting really bad , loose skin over my eyelids to the extent that I could no longer wear eyeshadow any longer, as it just creased every time I tried to wear it. At my first consultation, Dr Julian and his team immediately made me feel at ease. His wonderful team – Sarah, Mags, Mackez, Adam and the lovely nurses Alice, Josie and Kelly were all very kind and welcoming. I instantly felt at home.",
  },
  {
    text: "Dr Julian De Silva's kind, humble and calm nature made him stand out from the rest of surgeons that I had seen over the years. I didn't feel rushed, and he listened and understood my concerns. He explained to me about hypermobility, where the skin can stretch at a younger age and this made sense why I was seeing premature signs of ageing such as ptosis and jowls forming. I felt relieved that finally a surgeon understood how I was feeling and didn't immediately turn me away dismissing me because of my age saying I was too young etc.",
  },
];

export default function TestimonialsTextCarousel({
  data,
}: TestimonialsTextCarouselProps) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [api, setApi] = useState<CarouselApi>();

  useEffect(() => {
    if (!api) return;
    api.on("select", () => {
      setActiveIndex(api.selectedScrollSnap());
    });
  }, [api]);

  return (
    <section
      id="testimonials-text-carousel"
      className="bg-[#f4f4f4] py-8 md:py-14"
    >
      <div className="container max-w-[1200px] mx-auto px-4">
        <div className="text-center space-y-2 mb-4 md:mb-8">
          <h4 className="font-aileron font-semibold text-sm tracking-[0.2em] uppercase text-[#000000]">
            TESTIMONIAL
          </h4>
          <h2 className="font-display text-3xl md:text-[2.2rem] text-[#332f2c]">
            What our patients say about us...
          </h2>
          <p className="text-center font-aileron text-sm">
            Verified Google Review
          </p>
          <p className="text-4xl">*****</p>
        </div>
        <Carousel
          className="w-full max-w-[1080px] mx-auto"
          opts={{
            align: "start",
            loop: true,
          }}
          setApi={setApi}
        >
          <CarouselContent>
            {testimonials.map((testimonial, index) => (
              <CarouselItem key={index}>
                <div className="bg-[#655e5e] text-white p-8 md:p-16 flex items-center justify-center select-none">
                  <p className="text-sm font-aileron leading-[1.5rem] text-center max-w-[887px] ml-0 mr-0">
                    {testimonial.text}
                  </p>
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
          <div className="flex justify-center gap-2 mt-6">
            {testimonials.map((_, index) => (
              <button
                key={index}
                onClick={() => api?.scrollTo(index)}
                className={`w-2 h-2 rounded-full transition-all ${
                  activeIndex === index ? "bg-[#655e5e] w-4" : "bg-[#b2b2b2]"
                }`}
                aria-label={`Go to slide ${index + 1}`}
              />
            ))}
          </div>
        </Carousel>
        <div className="text-center mt-8">
          <Button variant="outline">
            <Link href="/about/patient-testimonials">
              READ MORE TESTIMONIALS
            </Link>
          </Button>
        </div>
      </div>
    </section>
  );
}
