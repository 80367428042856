import { sanitizeHTML } from "@/lib/utils";

interface VfrSevenFaceliftStepsData {
  heading: {
    title: string;
  };
  subheading: {
    text: string;
  };
  text: {
    text: string;
  };
}

interface VfrSevenFaceliftStepsProps {
  data: VfrSevenFaceliftStepsData;
}

export const fields = [
  "heading.title",
  "subheading.text",
  "text.text"
] as const;

export default function VfrSevenFaceliftSteps({ data }: VfrSevenFaceliftStepsProps) {
  return (
    <section
      id="vfr-seven-facelift-steps"
      className="bg-[#f9f9f9] py-[50px] md:py-[54px]"
    >
      <div className="container mx-auto px-4 md:px-8 max-w-[1400px] w-[90%]">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="space-y-4">
            <h2 className="font-freightBig text-[30px] md:text-[50px] text-[#655e5d] leading-tight">
              {data.heading.title}
            </h2>
            <div 
              className="prose prose-p:font-aileron prose-p:text-base prose-p:text-black"
              dangerouslySetInnerHTML={sanitizeHTML(data.subheading.text || '')}
            />
          </div>
          <div 
            className="prose prose-p:font-aileron prose-p:text-base prose-p:text-black space-y-4"
            dangerouslySetInnerHTML={sanitizeHTML(data.text.text || '')}
          />
        </div>
      </div>
    </section>
  );
}
