import Link from "@/components/ui/Link";
import { sanitizeHTML } from "@/lib/utils";

interface TestimonialSectionGreyData {
  heading?: {
    text?: string;
    title?: string;
  };
  subheading?: {
    text?: string;
    title?: string;
  };
  text?: {
    text?: string;
  };
  button?: {
    label?: string;
    href?: string;
  };
}

export const fields = [
  "heading.text",
  "heading.title",
  "subheading.text",
  "subheading.title",
  "text.text",
  "button.label",
  "button.href",
] as const;

export default function TestimonialSectionGrey({
  data,
}: {
  data: TestimonialSectionGreyData;
}) {
  return (
    <section
      id="TestimonialSectionGrey"
      className="w-full py-14 md:py-[4%] bg-[#e8e8e8]"
    >
      <div className="w-[90%] max-w-[820px] mx-auto px-4">
        <div className="flex flex-col items-center">
          <h4 className="font-aileron font-bold uppercase text-[13px] tracking-[2px] text-black mb-[18px] md:mb-[18px] text-center">
            {data?.subheading?.title}
          </h4>
          <h2 
            className="font-freightBig text-3xl md:text-5xl text-[#332f2c] leading-[1.1em] text-center mb-[35px] md:mb-[46px] max-w-[630px]"
            dangerouslySetInnerHTML={sanitizeHTML(data?.heading?.title || "")}
          />
          <div
            className="font-aileron text-[17px] md:text-[17px] leading-[1.5em] text-black !text-center mb-[40px] md:mb-[26px] [&>ul]:list-disc"
            dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || "")}
          />
          {data?.button && (
            <Link
              href={data?.button?.href || "#"}
              className="inline-flex items-center justify-center px-[35px] py-[15px] text-[12px] font-aileron font-bold uppercase tracking-[0.1em] text-[#332f2c] border border-[#b2b2b2] rounded-full transition-all duration-300 hover:bg-[#645e5e] hover:text-white hover:border-black"
            >
              {data?.button?.label}
            </Link>
          )}
        </div>
      </div>
    </section>
  );
}
