interface TextGrayProps {
  data?: any;
}

const list = [
  "ACE Aesthetics Annual Conference, invited speaker and teacher on Advanced Facial Anatomy, London, UK.",
  "American Association of Oculo Facial Plastic and Reconstructive Surgery Annual Conference, invited speaker and teacher for Facelift and Rhinoplasty Programme included International Lecturing and Anatomy Cadaver Surgery Demonstration, Chicago USA.",
  "Awarded the highest sum of National Funding for an innovative and pioneering new Leadership Programme in Healthcare, unprecedented award on two separate occasions.",
  "Revolutionised the delivery of community healthcare by training clinical members of staff to delivery care in a completely novel tripartite service, nominated for prestigious National Hospital Service Journal Award.",
  "Pioneered the first and only course for the support and development on new surgeons with Modernising of Medical Careers in the National Agenda for medical training.",
  "Won the prestigious Gold medal Research Prize as Surgical Trainee and Researcher at Imperial College London.",
  "Dr De Silva teaches on Advanced Face-Lifting Techniques at the 43rd Annual ASOPRS Scientific Symposium in Chicago, IL, USA.",
  "Dr De Silva teaches on Advanced Facial Anatomy at the ACE Aesthetics Conference in London, UK.",
];

export default function TextGray({ data }: TextGrayProps) {
  return (
    <section
      id="text-gray"
      className="bg-[#e8e8e8] py-[3.375rem] lg:py-[3.375rem]"
    >
      <div className="w-[90%] max-w-[51.25rem] mx-auto leading-tight text-[18px]">
        <div className="mb-[1rem] md:mb-[2.875rem]">
          <h2 className="font-display text-[1.875rem] lg:text-[2.1875rem] text-[#332f2c]  text-center">
            Teaching Honours
          </h2>
        </div>
        <div className="font-jakarta text-black">
          <p className="text-left mb-6">
            American Association of Oculo Facial Plastic and Reconstructive
            Surgery Annual Conference, invited speaker and teacher for Facelift
            and Rhinoplasty Programme included International Lecturing and
            Anatomy Cadaver Surgery Demonstration.
          </p>
          <ul className="list-disc pl-4 space-y-2">
            {list.map((item, index) => (
              <li key={index} className="text-left">
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}
