"use client";

import Image from "next/image";
import Button from "@/components/ui/button";
import Link from "@/components/ui/Link";
import { sanitizeHTML } from "@/lib/utils";
import { getReadableImageUrl } from "@/lib/directus";
import { useState } from "react";

interface VerticalFaceRestore7Data {
  heading?: {
    text?: string;
    title?: string;
  };
  text?: {
    text?: string;
  };
  buttons?: {
    menu_items_id: {
      href: string;
      label: string;
    };
  }[];
  content_collection?: {
    id: string;
    site_content_collections_id: {
      title: string;
      collection: {
        collection:string;
        item: {
          title: string;
          text: string;
          href: string;
          label: string;
        };
      }[];
    };
  }[];
  image?: {
    alt: string;
    image_file: {
      image: {
        id: string;
        filename_download: string;
      };
    };
  };
}

export const fields = [
  "heading.text",
  "heading.title",

  "text.text",
  
  "buttons.menu_items_id.href",
  "buttons.menu_items_id.label",
  
  "content_collection.id",
  "content_collection.site_content_collections_id.title",
  "content_collection.site_content_collections_id.collection.collection",
  "content_collection.site_content_collections_id.collection.item:site_content.title",
  "content_collection.site_content_collections_id.collection.item:site_content.text",
  "content_collection.site_content_collections_id.collection.item:menu_items.href",
  "content_collection.site_content_collections_id.collection.item:menu_items.label",


  "image.alt",
  "image.image_file.image.id",
  "image.image_file.image.filename_download"
] as const;

interface VerticalFaceRestore7Props {
  data?: VerticalFaceRestore7Data;
}

export default function VerticalFaceRestore7({
  data,
}: VerticalFaceRestore7Props) {
  const [activeTab, setActiveTab] = useState(0);

  const heading = data?.heading?.title || "";
  const description = data?.text?.text || "";

  const imageId = data?.image?.image_file?.image?.id || "";
  const imageDownload = data?.image?.image_file?.image?.filename_download || "";
  const imageSrc = getReadableImageUrl(imageId, imageDownload);
  const imageAlt = data?.image?.alt || "";
  
  const contentCollection = data?.content_collection || [];


  return (
		<section className='py-24 bg-white'>
			<div className='container max-w-[1560px] py-4 lg:py-4'>
				<div className='grid grid-cols-1 lg:grid-cols-[1fr_800px] gap-16'>
					<div className='flex flex-col justify-center h-full'>
						<h1 className='text-4xl lg:text-5xl font-freightBig mb-8 text-secondary-dark'>
							{heading}
						</h1>
					</div>
					<div className='backdrop-blur-lg lg:backdrop-blur-none rounded-3xl overflow-hidden px-6 lg:px-16'>
						<div
							className='text-secondary-dark prose'
							dangerouslySetInnerHTML={sanitizeHTML(description)}
						/>
					</div>
				</div>
			</div>

			<div className='relative min-h-[787px]'>
				<div className='absolute inset-0 w-full h-full'>
					<Image
						src={imageSrc}
						alt={imageAlt}
						fill
						className='object-cover object-center'
						quality={95}
						loading={'lazy'}
					/>
				</div>

				<div className='relative container min-h-[787px] flex items-center'>
					<div className='backdrop-blur-lg lg:backdrop-blur-none rounded-3xl overflow-hidden px-6 lg:px-16 py-12 lg:py-24 w-full lg:w-[800px] ml-auto bg-[rgb(215_188_158_/77%)] lg:bg-transparent'>
						<div className='flex flex-wrap gap-4 mb-16'>
							{contentCollection.map((collection, index) => (
								<Button
									key={index}
									variant={index === activeTab ? 'default' : 'outline'}
									onClick={() => setActiveTab(index)}
									className={`transition-all duration-300 ${
										index === activeTab ? 'scale-105' : 'hover:scale-105'
									}`}
								>
									{collection.site_content_collections_id.title || ''}
								</Button>
							))}
						</div>

						{contentCollection.map((collection, index) => {
							const titleItem =
								collection?.site_content_collections_id?.collection.find(
									item => item.collection === 'site_content' && item.item.title
								)?.item
							const textItem =
								collection?.site_content_collections_id?.collection.find(
									item => item.collection === 'site_content' && item.item.text
								)?.item
							const menuItem =
								collection?.site_content_collections_id?.collection.find(
									item => item.collection === 'menu_items'
								)?.item

							return (
								<div
									key={index}
									className={`transition-opacity duration-300 ${
										index === activeTab
											? 'block opacity-100'
											: 'hidden opacity-0'
									}`}
								>
									<h2 className='text-3xl lg:text-4xl font-freightBig mb-8 md:mb-12 max-w-lg leading-tight text-secondary-dark'>
										{titleItem?.title || ''}
									</h2>
									<div className='text-secondary-dark max-w-2xl prose'>
										<div
											dangerouslySetInnerHTML={sanitizeHTML(
												textItem?.text || ''
											)}
										/>
									</div>
									<div className='mt-8 md:mt-12'>
										{menuItem && (
											<Button variant='outline' asChild>
												<Link href={menuItem?.href || '#'}>
													{menuItem?.label || ''}
												</Link>
											</Button>
										)}
									</div>
								</div>
							)
						})}
					</div>
				</div>
			</div>
		</section>
	)
}
