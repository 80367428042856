import dynamic from "next/dynamic";
const ExpandingPanel = dynamic(() => import("./ExpandingPanel"), { ssr: false });

interface SpecialtyTrainingSectionData {
  content_collection: {
    id: number;
    site_content_collections_id: {
      collection: {
        item: {
          title?: string;
          text?: string;
        };
      }[];
    };
  }[];
}

interface SpecialtyTrainingSectionProps {
  data?: SpecialtyTrainingSectionData;
}

export const fields = [
  "content_collection.id",
  "content_collection.site_content_collections_id.collection.item:site_content.title",
  "content_collection.site_content_collections_id.collection.item:site_content.text",
] as const;

export default function SpecialtyTrainingSection({
  data,
}: SpecialtyTrainingSectionProps) {
  return (
    <section
      id="SpecialtyTrainingSection"
      className="bg-black py-12 md:py-14 lg:py-16"
    >
      <div className="container">
        <div className="max-w-[1080px] w-[90%] mx-auto">
          <div className="space-y-8">
            {data?.content_collection?.map((item) => (
              <ExpandingPanel
                key={item.id}
                title={item.site_content_collections_id.collection[0].item.title || ''}
                content={item.site_content_collections_id.collection[1].item.text || ''}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
